<template>
  <v-container>
    <v-card class="card-principal-niky">
      <div class="custom-background-image">
        <v-row>
          <v-col cols="12" sm="6" align-self="end">
            <div class="custom-card">
              <img v-if="$vuetify.breakpoint.smAndUp" src="@/assets/img/niky/niky_logo_simple.png" width="60" />
              <h1 :class="{ 'custom-title': true, 'mt-5': $vuetify.breakpoint.smAndUp }">
                Liberte suas <br />
                premiações <br />
                com a Niky!
              </h1>
              <h3 class="custom-subtitle mt-5">
                Junte-se aos milhares de <br />parceiros que já descobriram <br />
                as vantagens do App
              </h3>
              <div class="custom-div mt-7">
                <div>
                  <img src="@/assets/img/niky/niky_logo.png" width="100" class="mt-2" />
                </div>
                <v-divider vertical class="mx-5"></v-divider>
                <div>
                  <img src="@/assets/img/niky/quali_logo.png" width="150" class="mt-2" />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card class="card-funcionalidades-niky">
      <div v-if="$vuetify.breakpoint.smAndUp" class="custom-background-image">
        <img src="@/assets/img/niky/niky_cel_simple.png" class="custom-image" />
        <v-row>
          <v-col offset="6">
            <h1 class="custom-title">Com o App Niky, você pode:</h1>
            <div v-if="$vuetify.breakpoint.smAndUp" class="mt-5">
              <v-row no-gutters align="center" justify="center">
                <v-col sm="3" lg="2">
                  <img src="@/assets/img/niky/func_01.png" width="60" />
                </v-col>
                <v-col>
                  <span class="custom-strong-text">Controlar suas finanças.</span><br />
                  <span class="custom-text">Pague contas e acompanhe seu extrato.</span>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center" class="my-2">
                <v-col sm="3" lg="2">
                  <img src="@/assets/img/niky/func_02.png" width="60" />
                </v-col>
                <v-col>
                  <span class="custom-strong-text">Gerenciar seu saldo.</span><br />
                  <span class="custom-text">Saque ou transfira seu Qualicash.</span>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                <v-col sm="3" lg="2">
                  <img src="@/assets/img/niky/func_03.png" width="60" />
                </v-col>
                <v-col>
                  <span class="custom-strong-text">Aproveitar ao máximo.</span><br />
                  <span class="custom-text">
                    Use seu saldo Niky em tudo: alimentação, <br />
                    combustível, farmácia e muito mais!
                  </span>
                </v-col>
              </v-row>
              <div class="mb-3">
                <h1 class="custom-subtitle mb-5">E tem mais:</h1>
                <span class="custom-strong-text">Você não paga anuidade nem fatura!</span><br />
                <span class="custom-text mb-3">
                  Seus gastos são debitados na hora, direto da sua conta, <br />
                  sem cobranças no fim do mês.
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else class="custom-background-image">
        <img src="@/assets/img/niky/niky_cel.png" class="custom-image" />
        <v-row>
          <v-col offset="6">
            <h1 class="custom-title">Com o App Niky, você pode:</h1>
          </v-col>
        </v-row>
        <div class="custom-div">
          <v-row align="center" justify="center">
            <v-col cols="3" class="text-center">
              <img src="@/assets/img/niky/func_01.png" width="60" />
            </v-col>
            <v-col>
              <span class="custom-strong-text">Controlar suas finanças.</span><br />
              <span class="custom-text">Pague contas e acompanhe seu extrato.</span>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="3" class="text-center">
              <img src="@/assets/img/niky/func_02.png" width="60" />
            </v-col>
            <v-col>
              <span class="custom-strong-text">Gerenciar seu saldo.</span><br />
              <span class="custom-text">Saque ou transfira seu Qualicash.</span>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="3" class="text-center">
              <img src="@/assets/img/niky/func_03.png" width="60" />
            </v-col>
            <v-col>
              <span class="custom-strong-text">Aproveitar ao máximo.</span><br />
              <span class="custom-text"> Use seu saldo Niky em tudo: alimentação, combustível, farmácia e muito mais!
              </span>
            </v-col>
          </v-row>
        </div>
        <div class="pa-10">
          <h1 class="custom-subtitle mb-5">E tem mais:</h1>
          <span class="custom-strong-text">Você não paga anuidade nem fatura!</span><br />
          <span class="custom-text">
            Seus gastos são debitados na hora, direto da sua conta, <br />
            sem cobranças no fim do mês.
          </span>
        </div>
      </div>
    </v-card>
    <v-card class="card-download-niky">
      <div class="custom-background-image">
        <img v-if="$vuetify.breakpoint.lgAndUp" src="@/assets/img/niky/niky_cel_02.png" class="custom-image" />
        <v-row>
          <v-col cols="12" sm="7" align-self="end">
            <div class="custom-card-beneficios">
              <h1 class="custom-title">Baixe o aplicativo Niky Benefícios</h1>
              <v-row :no-gutters="$vuetify.breakpoint.mdAndDown" class="mt-7">
                <v-col cols="12" lg="6">
                  <div class="custom-div">
                    <img src="@/assets/img/niky/download_01.png" />
                    <span class="custom-text">
                      Abra o aplicativo e clique <br />
                      em "Primeiro acesso".
                    </span>
                  </div>
                  <div class="custom-div">
                    <img src="@/assets/img/niky/download_02.png" />
                    <span class="custom-text">Digite seu CPF e clique <br />
                      em "continuar".</span>
                  </div>
                  <div class="custom-div">
                    <img src="@/assets/img/niky/download_03.png" />
                    <span class="custom-text">
                      Leia e aceite os termos <br />
                      de uso e de privacidade.
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" lg="6">
                  <div class="custom-div">
                    <img src="@/assets/img/niky/download_04.png" />
                    <span class="custom-text">
                      Insira o código de validação <br />
                      que será enviado via SMS.
                    </span>
                  </div>
                  <div class="custom-div">
                    <img src="@/assets/img/niky/download_05.png" />
                    <span class="custom-text"> Caso haja divergência de dados de e-mail ou celular, por favor acionar o
                      Time Qualicorp. </span>
                  </div>
                  <div class="custom-div">
                    <img src="@/assets/img/niky/download_06.png" />
                    <span class="custom-text">Crie sua senha.</span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="custom-card-download-app">
              <h3 class="custom-subtitle">Agora você já pode acessar sua conta Niky Benefícios!</h3>
              <h1 class="custom-title mt-7">Baixe o App agora mesmo.</h1>
              <div class="custom-download-div mt-10">
                <a :href="getLink('Android')" target="_blank" class="mr-2">
                  <img src="@/assets/img/niky/btn_google_play.png" class="mt-2" />
                </a>
                <a :href="getLink('Apple')" target="_blank">
                  <img src="@/assets/img/niky/btn_app_store.png" class="mt-2" />
                </a>
              </div>
              <img v-if="$vuetify.breakpoint.mdAndDown" src="@/assets/img/niky/niky_cel_02.png" class="custom-image" />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card class="card-cartao-fisico-niky">
      <div class="custom-background-image custom-card">
        <h1 class="custom-title">
          Mas e o cartão físico? <br />
          Tem também?
        </h1>
        <v-row>
          <v-col cols="7" sm="6" align-self="end">
            <h3 class="custom-subtitle mt-5">
              Tem, sim! E a primeira via é grátis. <br />
              Se você quiser, é só solicitar o seu cartão físico Niky, <br v-if="$vuetify.breakpoint.mdAndUp" />
              um Mastercard prontinho pra uso, aceito em mais <br v-if="$vuetify.breakpoint.mdAndUp" />
              de 4 milhões de estabelecimentos do Brasil.
            </h3>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card class="card-aprenda-niky">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8" lg="6" xl="4">
            <h1 class="custom-title">
              Saiba como aproveitar ao <br />
              máximo seu App Niky ;)
            </h1>
          </v-col>
        </v-row>
        <div class="custom-div">
          <span class="custom-strong-text"> Solicite seu cartão físico. </span><br />
          <span class="custom-text">
            Acesse a página inicial e selecione "Meus Cartões". Clique no botão para solicitar o cartão físico e, em
            seguida, confirme o endereço de entrega, preenchendo CEP, número e complemento. O
            cartão será solicitado e o prazo de entrega pode variar por localidade, levando até 10 dias úteis.
          </span>
        </div>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Ative seu cartão físico.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                <strong>1. Primeiro acesso no app.</strong> <br />
                <p>
                  Baixe o aplicativo Niky Benefícios (Play Store ou App Store). Abra o App e clique em “Primeiro
                  acesso”. Digite seu CPF e clique em “continuar”. Leia e aceite os termos de uso e
                  privacidade. Insira o código de validação enviado via SMS (se houver divergência de dados de e-mail ou
                  celular, acione o Time Qualicorp). Crie sua senha e acesse sua conta.
                </p>
                <strong>2. Desbloqueie seu cartão.</strong> <br />
                <p>
                  Faça login no aplicativo Niky Benefícios, clique em “Meu Niky” e depois em “Ativar cartão”. Digite o
                  número do seu cartão e clique em “Ativar Cartão”. Escolha uma senha de quatro
                  dígitos, clique em “Enviar” e pronto, seu cartão está pronto para uso.
                </p>
                <strong>3. Primeiro uso.</strong> <br />
                <p class="mb-0">
                  Faça uma compra presencial, insira o cartão na maquininha e digite a senha. Após essa etapa inicial,
                  você poderá utilizar a função de aproximação para transações futuras.
                </p>
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Consulte seu saldo e extrato.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                Abra o App da Niky Benefícios. Na tela inicial, você verá o saldo das suas carteiras. Se o saldo estiver
                com **** (asteriscos), clique no ícone de olho para visualizar o valor
                disponível. Para acessar seu extrato, clique no ícone "Extrato" na tela inicial, onde todas as suas
                despesas serão detalhadas.
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Altere a senha do seu cartão Niky pelo App.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                Faça o login no aplicativo "Niky Benefícios", acesse a seção "Meu Niky", clique em "Senha" e selecione
                "Alterar senha". Digite sua senha atual e, em seguida, insira a nova para
                concluir a alteração. Se você esqueceu a senha do App, abra o aplicativo, clique em "Esqueci a senha" na
                tela de login e siga as instruções para redefini-la, o que geralmente envolve
                receber um e-mail com um link de redefinição ou responder a perguntas de segurança.
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Entre em contato com o suporte.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                Se você precisa de ajuda e quer entrar em contato com o suporte da Niky, você pode enviar um e-mail para
                faleconosco@niky.com.vc, acessar o site https://bit.ly/suporte-niky ou ligar
                para 3003-7316 ou 0800-575-0069. O suporte está disponível de segunda a sexta-feira, das 8h às 18h
                (exceto feriados).
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Solicite a segunda via do cartão.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                Para solicitar a segunda via do cartão Niky, você pode entrar em contato diretamente com seu
                departamento de Premiações Qualicorp. Caso não disponibilizem um cartão reserva, eles devem
                encaminhar sua solicitação ao suporte.
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Transfira dinheiro via TED para qualquer banco.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                Acesse o App e selecione “Serviços Financeiros” no menu principal. Escolha “Transferência bancária” e
                siga as instruções, certificando-se de que a transferência bancária seja feita exclusivamente para sua
                conta pessoal, confirmando os dados antes de concluir. A tarifa de serviço para TED é de R$ 9,90 e será
                exibida após a confirmação da transação. O valor será creditado na conta indicada em até 1 dia útil.
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Pague contas e boletos.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                Acesse o App e selecione “Serviços Financeiros” no menu principal. Clique em “Pagar Boleto” e selecione
                a carteira para uso do saldo. Digite o código do boleto a ser pago ou selecione
                “Escanear” e aponte a câmera do celular para o código de barras, aguardando a leitura. Verifique as
                informações de pagamento, clique em “Continuar” e digite sua senha. Pronto, boleto
                pago (a taxa de pagamento de boleto é de R$ 3,00).
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2>
                <strong>Faça saques.</strong>
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <big>
                Insira o cartão em um terminal do Banco24h e selecione “Saque a Crédito”. Siga as instruções no caixa
                eletrônico e pronto! A tarifa de serviço cobrada pela rede 24h para essa transação
                com o cartão físico é de R$ 9,90.
              </big>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
    <v-card class="card-contato-niky" color="#18264C">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8" lg="6" xl="4">
            <h1 class="custom-title">Ficou com alguma dúvida?<br />Fale com o TamoJunto no WhatsApp:</h1>
          </v-col>
        </v-row>
        <div class="flex-container mt-5">
          <div class="d-flex flex-column">
            <img src="@/assets/img/niky/contato_whatsapp.png" width="50" />
          </div>
          <div class="d-flex flex-column flex-30 ml-0 ml-md-3 mt-1">
            <a href="https://api.whatsapp.com/send?phone=5511930304630" class="contato-title" target="_blank">(11)
              93030-4630</a>
            <span class="contato-subtitle">(Brasil)</span>
          </div>
          <div class="d-flex flex-column mt-5 mt-md-0">
            <img src="@/assets/img/niky/contato_niky.png" width="50" />
          </div>
          <div class="d-flex flex-column flex-50 ml-0 ml-md-3 mt-2 mt-md-1 mt-xl-1">
            <div class="contato-title">
              Ou envie uma mensagem pelo <br />
              App Niky na opção "Fale Conosco".
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Niky",
  methods: {
    getLink(tipo) {
      let link = "";
      if (tipo === "Apple") {
        link = "https://apps.apple.com/br/app/niky-benef%C3%ADcios/id6462306322";
      }
      if (tipo === "Android") {
        link = "https://play.google.com/store/apps/details?id=vc.com.niky.beneficios&pcampaignid=web_share";
      }
      return link;
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/variables";

.flex-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: $media-md) {
  .flex-container {
    flex-direction: column;
  }
}

.flex-30 {
  flex: 0 0 30%;
}

.flex-50 {
  flex: 0 0 50%;
}

.card-principal-niky {
  @media (max-width: $media-sm) {
    .custom-background-image {
      background: url("~@/assets/img/niky/niky_mob_01.png") no-repeat center;
      background-size: 100% 100%;
      min-height: 150vh;
    }

    .custom-card {
      padding-top: 2rem;
      text-align: center;
    }

    .custom-div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: $media-sm) {
    .custom-background-image {
      background: url("~@/assets/img/niky/niky_01.png") no-repeat center;
      background-size: 100% 100%;
      min-height: 100vh;
    }

    .custom-card {
      padding-top: 3rem;
      padding-left: 3rem;
    }

    .custom-div {
      display: flex;
    }
  }

  .custom-title {
    font-weight: 600;
    font-size: 2.5em;
    color: #182642;
    font-weight: bold;
    font-family: "Galano Grotesque";
  }

  .custom-subtitle {
    font-weight: 600;
    font-size: 1em;
    color: #182642;
    font-family: "Galano Grotesque";
  }
}

.card-funcionalidades-niky {
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: $media-sm) {
    .custom-background-image {
      min-height: 80vh;
    }

    .custom-image {
      position: absolute;
      top: -10%;
      left: 5%;
      right: 5%;
      width: 90%;
      height: 30%;
    }

    .custom-title {
      font-weight: 600;
      font-size: 1.5em;
      color: #182642;
      font-weight: bold;
      font-family: "Galano Grotesque";
      margin-top: 0.5rem;
    }

    .custom-subtitle {
      font-weight: 600;
      font-size: 1.2em;
      color: #182642;
      font-weight: bold;
      font-family: "Galano Grotesque";
    }

    .custom-div {
      padding: 20% 10% 0 5%;
    }

    .custom-strong-text {
      font-size: 1em;
      color: #182642;
      font-weight: bold;
      font-family: "Galano Grotesque";
    }

    .custom-text {
      font-size: 0.8em;
      color: #182642;
      font-family: "Galano Grotesque";
    }
  }

  @media (min-width: $media-sm) {
    .custom-background-image {
      background: url("~@/assets/img/niky/background_white.png") no-repeat center;
      background-size: 100% 100%;
      min-height: 60vh;
    }

    .custom-image {
      position: absolute;
      top: -10%;
      left: 10%;
      width: 30%;
      height: 110%;
    }

    .custom-title {
      font-weight: 600;
      font-size: 1.5em;
      color: #182642;
      font-weight: bold;
      font-family: "Galano Grotesque";
      margin-top: 1rem;
    }

    .custom-subtitle {
      font-weight: 600;
      font-size: 1.2em;
      color: #182642;
      font-weight: bold;
      font-family: "Galano Grotesque";
      margin-top: 1rem;
    }

    .custom-strong-text {
      font-size: 1em;
      color: #182642;
      font-weight: bold;
      font-family: "Galano Grotesque";
    }

    .custom-text {
      font-size: 0.8em;
      color: #182642;
      font-family: "Galano Grotesque";
    }
  }
}

.card-download-niky {
  @media (max-width: $media-md) {
    .custom-background-image {
      background: url("~@/assets/img/niky/background_blue_02.png") no-repeat center;
      background-size: 100% 100%;
      min-height: 50vh;
    }

    .custom-image {
      display: block;
      margin: 0 auto;
    }

    .custom-card-beneficios {
      margin-top: 3rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .custom-card-download-app {
      text-align: center;
    }

    .custom-download-div {
      margin-bottom: 3rem;
    }
  }

  @media (min-width: $media-md) {
    .custom-background-image {
      background: url("~@/assets/img/niky/background_blue.png") no-repeat center;
      background-size: 100% 100%;
      min-height: 50vh;
    }

    .custom-image {
      position: absolute;
      bottom: 0%;
      right: 5%;
    }

    .custom-card-beneficios {
      padding-top: 3rem;
      padding-left: 3rem;
    }

    .custom-card-download-app {
      padding-left: 3rem;
    }

    .custom-download-div {
      display: flex;
      align-items: center;
      height: 2rem;
      margin-bottom: 3rem;
    }
  }

  .custom-title {
    font-weight: 600;
    font-size: 2.5em;
    color: #ddfd77;
    font-weight: bold;
    font-family: "Galano Grotesque";
  }

  .custom-subtitle {
    font-weight: 600;
    font-size: 1.5em;
    color: #ffffff;
    font-weight: bold;
    font-family: "Galano Grotesque";
  }

  .custom-div {
    display: flex;
    align-items: center;
    height: 2rem;
    margin-bottom: 3rem;
  }

  .custom-text {
    margin-left: 0.7rem;
    font-size: 1em;
    color: #ffffff;
    font-family: "Galano Grotesque";
  }
}

.card-cartao-fisico-niky {
  @media (min-width: $media-md) {
    .custom-background-image {
      background: url("~@/assets/img/niky/background_green.png") no-repeat center;
      background-size: 100% 100%;
      min-height: 50vh;
    }

    .custom-card {
      padding-top: 3rem;
      padding-left: 3rem;
    }
  }

  @media (max-width: $media-md) {
    .custom-background-image {
      background: url("~@/assets/img/niky/background_green_02.png") no-repeat center;
      background-size: 100% 100%;
      min-height: 70vh;
    }

    .custom-card {
      padding-top: 2rem;
      padding-left: 2rem;
    }
  }

  .custom-title {
    font-weight: 600;
    font-size: 2em;
    color: #182642;
    font-weight: bold;
    font-family: "Galano Grotesque";
  }

  .custom-subtitle {
    font-weight: 500;
    font-size: 1.2em;
    color: #182642;
    font-family: "Galano Grotesque";
  }
}

.card-aprenda-niky {
  font-family: "Galano Grotesque";

  .custom-title {
    font-size: 2.5em;
    line-height: 1.2;
    padding: 1rem 1.5rem;
    color: #000000;
  }

  .custom-div {
    padding: 16px 24px;
  }

  .custom-strong-text {
    font-size: 1.2em;
    color: #182642;
    font-weight: bold;
    font-family: "Galano Grotesque";
  }

  .custom-text {
    font-weight: 400;
    font-size: 1.2em;
    color: #182642;
    font-family: "Galano Grotesque";
  }
}

.card-contato-niky {
  font-family: "Galano Grotesque";
  padding: 2rem;

  .custom-title {
    font-size: 2em;
    line-height: 1.2;
    padding: 0;
    font-weight: 600;
    color: #ddfd77;
  }

  .contato-title {
    font-size: 1.8em;
    font-weight: 600;
    text-decoration: none;
    color: #ffffff;
  }

  .contato-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6em;
    color: #ffffff;
  }
}
</style>
